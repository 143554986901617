import styled from "styled-components";

export const GridImg = styled.div`
  display: flex;

  margin: auto;
  width: 100%;
 
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);
`;
