import styled from "styled-components";

export const Container = styled.div`
  background-color: #34b168;
  display: flex;
  max-width: 100%;
  min-height: 410px;
  margin: auto;
  position: none;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Grid1 = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const Grid2 = styled.div`
  display: flex;
  max-width: 500px;
  margin: auto;

  h1 {
    display: flex;
    color: #acfa78;
    @media (max-width: 786px) {
      display: flex;

      margin: auto;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Grid3 = styled.div`
  max-width: 500px;
  margin: auto;

  p {
    color: #ffffff;
    @media (max-width: 768px) {
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }
`;

export const Grid4 = styled.div`
 
  width: 50%;
  position: static;


  @media (max-width: 768px) {
    width: 100%;
  }
`;
