const data = [
/*   {
    id: 1,
    category: "Sobre",
    image: '../images/destinacao_final/img1.png',    
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/destinacao_final/img2.png',
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/destinacao_final/img3.png',
    image_alt: "Sobre"
  }, */
  {
    id: 3,
    category: "Sobre",
    image: '../images/destinacao_final/img4.png',
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/destinacao_final/img5.png',
    image_alt: "Sobre"
  },
  
  
  
];

export default data;
