import React from "react";
import { SpaceContainer } from "../../components/SpaceContainer/SpaceContainer";
import { MissaoVisao } from "../Home/MissaoVisao/MissaoVisao";
import { Sobre } from "../Home/Sobre/Sobre";
import { PageValores } from "../Valores/PageValores";

export function PageSobre() {
  return (
    <>
      <Sobre />
      <MissaoVisao />
      <PageValores />
      <SpaceContainer />
    </>
  );
}
