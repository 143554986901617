import React from "react";
import { ServiceOne } from "../Home/ServiceOne/ServiceOne";
import { ServiceTwo } from "../Home/ServiceTwo/ServiceTwo";

export function Servicos() {
  return (
    <>
      <ServiceOne />
      <ServiceTwo />
      <ServiceOne />
    </>
  );
}
