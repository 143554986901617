import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Destinacao() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Armazenamento</span>
            </h1>

            <p>
            Dispomos de tanques metálicos e de concreto para armazenamento de líquidos, atualmente possuímos uma capacidade instalada de 5,7 mil metros cúbicos, com possibilidade de ampliação desta capacidade. Os tanques são utilizados para armazenamento dos resíduos líquidos coletados, que posteriormente são enviados ao processo de tratamento, e também são disponibilizados pra locação para armazenamento de diversos produtos, como fluido para perfuração de poços de petróleo e gás, onde somos pioneiros no Estado a oferecer este serviço a nossos clientes.
            </p>
          </Grid2>
          <ContainerImg>
            <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
