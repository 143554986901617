import styled from "styled-components";

export const ButtomYeloow = styled.div`
  display: flex;
  max-width: 200px;
  height: 50px;
  left: 314px;
  top: 2281px;
  background: #ffb800;
  border-radius: 28.5px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #15a54f;
    color: #ffffff;
    border-color: #ffffff;
    font-weight: bold;
  }

  p {
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  h3 {
    color: #ffff;
    margin: auto;
  }

  a {
    text-decoration: none;
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtomGreen = styled.div`
  display: flex;
  max-width: 200px;
  height: 50px;
  left: 314px;
  top: 2281px;
  background: #15a54f;
  border-radius: 28.5px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #ffb800;
    color: #ffffff;
    border-color: #ffffff;
    font-weight: bold;
  }

  p {
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  h3 {
    color: #ffff;
    margin: auto;
  }

  a {
    text-decoration: none;
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtomWhatsApp = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  height: 50px;
  margin-left: auto;
  background-image: linear-gradient(to right,#0a8633,#10e670);
  border-radius: 28.5px;
  justify-content: flex-end; 
  cursor: pointer;

  &:hover {
    background-color: #1dc41d;
    color: #ffffff;
    border-color: #ffffff;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 6px;
  }

  img {
    display: flex;
    width: 30px;
    align-items: flex-end;
    justify-content: end;
    margin-left: 8px;
  }

  @media (max-width: 700px) {
    margin: auto;
  }
`;

export const ButtomBlue = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 150px;
  height: 50px;
  left: 314px;
  top: 2281px;
  background: #2b43bd;
  border-radius: 28.5px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #00ff55;
    color: #ffffff;
    border-color: #ffffff;
    font-weight: bold;
  }

  p {
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  h3 {
    color: #ffff;
    margin: auto;
  }

  a {
    text-decoration: none;
    color: #ffff;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
`;

export const Buttom = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 7px;
  width: 150px;
  height: 40px;
  background-color: #ffffff11;
  border-color: #03fd63;
  color: #ffffff;
  justify-content: center;

  padding: 20px;
  &:hover {
    background-color: #28e496;
    color: #00880b;
    border-color: #ffffff;
    font-weight: bold;
  }
`;