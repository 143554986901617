import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function ColetaTransporte() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Coleta e transporte de resíduos.</span>
            </h1>

            <p>
            A JC Ambiental possui uma frota variada pronta para atender às diversas necessidades de seus clientes, equipamentos revisados e rastreamento 24 horas por dia.
            </p>
          </Grid2>
          <ContainerImg>

          <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
