import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Limpeza() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Limpezas Industriais.</span>
            </h1>

            <p>
            Dispomos de equipe especializada para limpezas, habilitada para atividades em Espaço Confinado NR-33, Trabalho em Altura NR-35, Líquidos Combustíveis e Inflamáveis -NR-20, onde realizamos Limpeza e higienização de Caixa D’água, Limpeza de Tanques e Caixas  Separadoras.
            </p>
          </Grid2>
          <ContainerImg>
            <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
