import React from 'react'
import { Container } from './Style'

export function SpaceContainer() {
  return (
    <>
    <Container />    
    </>
    
  )
}

