import React from "react";
import { CarrouselContainer } from "../../../components/Carrousel/CarrouselContainer";

import { Container, Grid1, Grid2, Grid3, Grid4 } from "./style";

export function BannerTopo() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Seja bem vindo(a) a sua nova gestora de resíduos.</span>
            </h1>
          </Grid2>
          <Grid3>
            <p>
              Atuamos em todo o Norte/Nordeste do país há mais de 10 anos,
              prestando serviços de qualidade em coleta, transporte, tratamento,
              destinação final de resíduos e limpezas Industriais.
            </p>
          </Grid3>
        </Grid1>
        <Grid4>
          <CarrouselContainer />
        </Grid4>
      </Container>
    </>
  );
}
