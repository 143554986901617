import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Responsabilidade() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Responsabilidade socioambiental</span>
            </h1>

            <p>
            Temos como objetivo, estimular a mudança de atitudes em relação ao meio ambiente e a sociedade através da educação, com o intuito de melhorar a qualidade de vida para a atual e as futuras gerações.
            </p>
          </Grid2>
          <ContainerImg>
            <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
