import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 25px;
  margin-bottom: 10px;
`;

export const Grid1 = styled.div`
  width: 250px;
  max-height: 400px;
  margin: 10px;
  left: 194px;
  top: 853px;

  flex: 1 1 450px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #2b43bd;
  }

  p { 
    max-width: 450px;
    margin: auto;
    padding: 10px;
  }

`;

export const Grid2 = styled.div`
  display: flex;  
  flex: 1 500px;
  img {
    margin: auto;
    max-width: 450px;
  }
`;




export const Circle = styled.div`
  width: 40px;
  height: 40px;
  left: 233px;
  top: 940px;
  border-radius: 100%;
  background: #2b43bd;
  display: flex;
  margin: 5px;
  margin-bottom: 10px;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    margin: auto;
    align-items: center;
  }
`;
