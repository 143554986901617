import React from "react";
import { BannerTopo } from "./Banner/BannerTopo";

import { Sobre } from "./Sobre/Sobre";
import { MissaoVisao } from "./MissaoVisao/MissaoVisao";
import { Clientes } from "./Clientes/Clientes";
import { Valores } from "./Valores/Valores";
import { Services } from "./Servicos/Services";



export function Home() {
  return (
    <>
      <BannerTopo />
      <Services />
      <Sobre />
      <MissaoVisao />
      <Valores />
      <Clientes />
    </>
  );
}
