import styled from "styled-components";

export const Container = styled.header`
  padding: 10px;
  width: 100%;
  @media (max-width: 700px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const Grid = styled.div`
  max-width: 1555px;
  display: flex;
  margin: auto;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

export const BoxCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 100%;

  @media (max-width: 700px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    order: 2;
  }
`;

export const Grid1 = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin: auto;

  @media (max-width: 700px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 10px;
    order: 2;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;

    li {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      width: 160px;
      height: 160px;
      padding: 2px;
      border-radius: 10px;
      border: 1px solid #91c2af;
      cursor: pointer;

      :first-child {
        background: #34b175;
        p {
          color: #fff;
        }

        svg {
          width: 60px;
          display: flex;
          color: #189441;
          fill: #acfa78;
        }

        :hover {
          svg {
            transform: scale(1.1);
            transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
            fill: #fff;
          }
        }
      }

      p {
        text-align: center;
        color: #189441;
        padding: 5px;
      }

      a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        margin: auto;
        align-items: center;
        justify-content: center;
      }

      :hover {
        background: #34b175;
        p {
          color: #fff;
        }
        svg {
          transform: scale(1.1);
          transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
          fill: #fff;
        }
      }

      svg {
        width: 45px;
        display: flex;
        fill: #00a766;
      }
    }
  }
`;

export const Grid2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  height: 100%;
  margin: auto;
  justify-content: center;
  text-align: center;

  header {
    justify-content: center;
    margin: auto;

    p {
      margin-top: 20px;
      padding: 10px;
      margin: auto;
    }
  }

  @media (max-width: 700px) {
    display: flex;
    order: 1;
  }

  h2 {
    justify-content: center;
    margin: auto;
  }
  @media (max-width: 700px) {
    order: 1;
    h2 {
      margin-top: 20px;
      padding: 10px;
    }
  }

  p {
    max-width: 350px;
    margin-bottom: 20px;
  }

  @media (max-width: 700px) {
    width: 100%;
    order: 2;
  }
`;

export const Grid3 = styled.div`
  align-items: flex-end;
  margin: auto;
  padding: 10px 10px;

  > section {
    display: flex;
    margin: auto;
    gap: 2rem;
    &:last-child {
      gap: 1rem;
    }

    > nav {
      display: flex;
      gap: 1rem;

      a {
        font-size: 20px;
        position: relative;
        text-decoration: none;
        align-items: center;
        color: #fff;

        &:before {
          content: "";
          border-radius: 50px;
          bottom: -10px;
          position: absolute;
          width: 0%;
          height: 10px;

          transition: 0.3s;
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 900px) {
      .mobile {
        display: initial;
      }
      > nav {
        display: none;
      }
    }

    font-size: 18px;
    position: relative;
    text-decoration: none;
    align-items: center;
    color: #fff;
  }

  @media (max-width: 700px) {
    padding: 14.5px 16px;
    font-size: 20px;
    position: relative;
    text-decoration: none;
    align-items: center;
    color: #fff;
  }

  @media (max-width: 700px) {
    width: 100%;
    order: 2;
  }
`;
