import React from "react";
import { Container, Grid1, Grid2 } from "./style";

export function MissaoVisao() {
  return (
    <>
      <Container>
        <Grid1>
          <h2>Nossa missão</h2>
          <p>
            Ser líder no segmento de coleta, transporte, tratamento e destinação
            final de resíduos no estado do Maranhão.
          </p>
        </Grid1>
        <Grid2>
          <h2>Nossa Visão</h2>
          <p>
          Ser reconhecida como uma empresa dinâmica, que apresenta soluções e produtos adequados á seus clientes. Alcançando assim, a confiança e os melhores resultados técnicos no seguimento atuante. Além disso, ser lembrada pela eficiência impar em oferecer qualidade no atendimento e os melhores preços relativos do mercado.
          </p>
        </Grid2>
      </Container>
    </>
  );
}
