import React from 'react'
import { Valores } from '../Home/Valores/Valores'



export  function PageValores() {
  return (
    <>
  <Valores />

    </>
  )
}
