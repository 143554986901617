const data = [
  {
    id: 1,
    category: "Sobre",
    image: '../images/gerenciamento/img1.png',    
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/gerenciamento/img2.png',
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/gerenciamento/img3.png',
    image_alt: "Sobre"
  },  
  {
    id: 4,
    category: "Sobre",
    image: '../images/gerenciamento/img4.png',
    image_alt: "Sobre"
  }, 
  {
    id: 5,
    category: "Sobre",
    image: '../images/gerenciamento/img5.png',
    image_alt: "Sobre"
  }, 
  {
    id: 6,
    category: "Sobre",
    image: '../images/gerenciamento/img6.png',
    image_alt: "Sobre"
  }, 
  {
    id: 7,
    category: "Sobre",
    image: '../images/gerenciamento/img7.png',
    image_alt: "Sobre"
  }, 
  
  {
    id: 8,
    category: "Sobre",
    image: '../images/gerenciamento/img8.png',
    image_alt: "Sobre"
  }, 
  
  
];

export default data;
