import styled from "styled-components";

export const Container = styled.header`
  background: linear-gradient(to right, #d7d7d7, #c5c1c1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
`;

export const Grid = styled.div`
  background: linear-gradient(to right, #34b175, #3ac984, #34b175, #057a42);

  display: flex;
  margin: auto;

  > img {
    width: 100px;
    padding: 10px;
    margin-left: 25px;
  }
`;

export const Grid1 = styled.nav`
  display: flex;
  width: 100%;
  align-items: flex-start;

  @media (max-width: 700px) {
    width: 100%;
  }

  section {
    display: flex;
    margin: auto;
    gap: 2rem;
    &:last-child {
      gap: 1rem;
    }

    img {
      width: 30px;
      @media (max-width: 700px) {
        width: 20px;
      }
    }

    > nav {
      display: flex;
      margin: auto;
      display: flex;

      @media (max-width: 700px) {
        display: none;
      }

      /* Menu Principal */
      ul {
        width: 100%;
        list-style-type: none;

        li {
          margin: auto;
          display: inline-block;
          svg {
            width: 45px;
            display: flex;
            fill: #20c997;
            padding: 5px;
          }

          a {
            width: 100%;
            display: flex;
            margin: auto;
            text-decoration: none;
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            padding: 10px;

            :hover {
              svg {
                transform: scale(1.1);
                transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
                fill: #acfa78;
                width: 50px;
              }
            }

            &:hover {
              width: 100%;
              display: flex;
              color: #00da74;
              background-color: #34b175;
              border: none;
              padding: 5;
              transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
            }
          }
          /* submenu */

          ul {
            display: none;
            background-color: #34bd7d;
          }
          &:hover ul {
            position: absolute;
            width: 300px;
            margin: auto;
            display: flex;
            flex-direction: column;
            border-radius: 4px;
            /* Adicionar um Popup */
            background-color: #34bd7d;
            box-shadow: 0px 0px 10px #037432;
            z-index: 1;
            transition: 0.2s;
            /* padding: 10px; */

            margin-left: -100px;
            margin-right: 10px;
            margin-bottom: 10px;
            &:hover {
              background-color: #34bd7d;
            }
          }

          li {
            margin: auto;
            display: flex;
            width: 100%;
            background-color: #faf6f1;
            border: solid 1px #f3f3f3;

            a {
              color: #087741;
              font-size: 13px;
              align-items: center;

              :hover {
                color: #ececec;
              }
            }
          }
        }
      }
      .mobile {
        display: none;
        a {
          text-decoration: none;
          color: #fff;
        }
      }

      @media (max-width: 900px) {
        .mobile {
          display: initial;
        }
        > nav {
          display: none;
        }
      }

      font-size: 18px;
      position: relative;
      text-decoration: none;
      align-items: center;
      color: #fff;

      @media (max-width: 700px) {
        padding: 14.5px 16px;
        font-size: 20px;
        position: relative;
        text-decoration: none;
        align-items: center;
        color: #fff;
      }
    }
  }
`;

export const Grid2 = styled.div`
  display: flex;
  margin: auto;
  padding: 10px 10px;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
`;

export const Hamburger = styled.div`
  background-color: #34b175;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  margin-left: 10px;

`;

export const SubLogo = styled.div`
display: flex;
margin: auto;
img {
  width: 90%;
}


  @media (max-width: 700px) {
    
    display: none;
  }


`;

export const Grid3 = styled.div`
  position: fixed;
  background-color: #110707;
  align-items: flex-end;
  margin: auto;

  /*   > section {
    display: flex;
    margin: auto;
    gap: 2rem;
    &:last-child {
      gap: 1rem;
    }

    > img {
      width: 60px;
      @media (max-width: 200px) {
        width: 120px;
      }
    }

    > nav {
      display: flex;
      gap: 1rem;

      a {
        font-size: 20px;
        position: relative;
        text-decoration: none;
        align-items: center;
        color: #fff;

        &:before {
          content: "";
          border-radius: 50px;
          bottom: -10px;
          position: absolute;
          width: 0%;
          height: 10px;

          transition: 0.3s;
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
    .mobile {
      display: none;
      a {
        text-decoration: none;
        color: #fff;
      }
    }

    @media (max-width: 900px) {
      .mobile {
        display: initial;
      }
      > nav {
        display: none;
      }
    }

    font-size: 18px;
    position: relative;
    text-decoration: none;
    align-items: center;
    color: #fff;
  } */

  @media (max-width: 700px) {
    padding: 14.5px 16px;
    font-size: 20px;
    position: relative;
    text-decoration: none;
    align-items: center;
    color: #fff;
  }

  @media (max-width: 700px) {
    order: 2;
  }
`;
