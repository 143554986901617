import styled from "styled-components";

export const Container = styled.div`
background-color: #34b175;
  border-radius: 4px;
  display: flex;
  max-width: 1555px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: wrap;
`;

export const Grid1 = styled.div`
  background-color: #34b175;
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: 1 2 500px;
  border-radius: 4px;
  padding: 5px;
  margin-right: 2px;
 

  
  @media (max-width: 700px) {
    order: 2;
  }

  p {
    padding: 30px;
    color: #ffff;
  }
  h2{   
    color: #acfa78;
  }
`;

export const Grid2 = styled.div`
  background-color: #34b175;
  display: flex;
  width: 50%;  
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: 1 2 500px;
  border-radius: 4px;
  padding: 5px;

  @media (max-width: 700px) {
    order: 2;
  }

  p {
    padding: 30px;
    color: #ffff;
  }
  h2{
    color: #acfa78;
  }
`;
