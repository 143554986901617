const depoiment = [
  {
    id: 1,   
    image: '../../../images/clientes/01.png',
    image_alt: 'imagem do Cliente',  
   
  },
  {
    id: 2,   
    image: '../../../images/clientes/02.png',
    image_alt: 'imagem do Cliente', 
  },
  {
    id: 3,     
    image: '../../../images/clientes/03.png',
    image_alt: 'imagem do Cliente',   
   
  },
  {
    id: 4,   
    image: '../../../images/clientes/04.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/05.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/06.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/07.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/08.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/09.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/10.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/11.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/12.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/13.png',
    image_alt: 'imagem do Cliente',     
   
  },
  {
    id: 5,   
    image: '../../../images/clientes/14.png',
    image_alt: 'imagem do Cliente',     
   
  },
];

export default depoiment;
