import styled from "styled-components";

export const Background = styled.div`
  background: ${(props) => props.color};
`;

export const Tipograf = styled.div`
  h1 {
    font-style: normal;
    font-weight: 100;
    font-size: 3em;
    line-height: 64px;
    letter-spacing: 0.01em;
    color: #00A766;
    font-weight: 900;
    line-height: 64px;
    letter-spacing: -0.3rem;
    font-size: 4em;
    margin-top: 50px;
  }


  h2 {
    text-align: center;
    color: #00A766;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: -0.2rem;
    font-size: 2.55em;
    margin-top: 50px;
  }

  h3 {
    text-align: center;
    color: #00A766;
    font-weight: 900;
    line-height: 20px;
    font-size: 2.2em;
  }

  h4 {
    text-align: center;
    color: #00A766;
    font-weight: 900;
    line-height: 20px;
    font-size: 1.5em;
  }

  p {
    color: #4d4c4c;
    font-size: 1em;
    font-weight: 400;
    font-family: Roboto, sans-serif;
  }

  strong {
    font-size: 1em;
    text-align: center;
    font-weight: bold;
    color: #01975e;
  }
`;
