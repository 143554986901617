import React from "react";
import { FormContato } from "../../components/forms/contato/FormContato";

import { Container, Grid1, Grid2, Grid3, Grid4, Grid5 } from "./style";

export function Contato() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <FormContato />
          </Grid2>
        </Grid1>
        <Grid3>
          <Grid4>
            <h2>Onde estamos</h2>
            <p>Vila Maracujá, R. José Tupinambá, 250, São Luís - MA</p>
          </Grid4>

          <Grid5>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.543961463451!2d-44.27009014909125!3d-2.6523775393818667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f6831f936054e3%3A0xd44bbfd57ba32587!2sJCAmbiental!5e0!3m2!1spt-BR!2sbr!4v1650202108897!5m2!1spt-BR!2sbr"
            
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Mapa"
              ></iframe>
            </div>
          </Grid5>
        </Grid3>
      </Container>
    </>
  );
}
