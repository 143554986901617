const data = [
  {
    id: 1,
    category: "Sobre",
    image: "../../../../images/sobre/visao.jpeg",
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: "../../../../images/sobre/working2.png",
    image_alt: "Sobre"
  },
  
];

export default data;
