import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  display: flex;

  max-width: 1555px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: wrap;
`;

export const Grid1 = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: 1 2 500px;

  @media (max-width: 700px) {
    order: 2;
  }

  p {
    padding: 30px;
  }
`;

export const Grid2 = styled.div`

  display: flex;
 
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: 1 2 500px;




  @media (max-width: 700px) {
    order: 2;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 8px;

  > img {
      width: 100%;
      border-radius: 4px;
      @media (max-width: 700px) {
        width: 100%;
       
      }

    
  }
`;
