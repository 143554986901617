import React from "react";


export function Description() {
  return (
    <>
      
        <header>
          <h2>Gestão com eficiência e profissionalismo.</h2>
          <p>
            Aqui na JC Ambiental realizamos não somente a coleta mas a gestão
            completa de resíduos da sua empresa, assim você pode se concentrar
            em seu negócio enquanto nós cuidamos do resto.
          </p>
        </header>
      
    </>
  );
}
