import styled from "styled-components";

export const Container = styled.div`
  background-color: #faf6f1;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 2px;
`;

export const Grid1 = styled.div`

  display: flex;
  margin: auto;
`;

export const Grid2 = styled.div`
max-width: 1555px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  flex: 1 2 200px;
`;

export const Card = styled.div`
display: flex;
  width: 200px;
  height: 100px;
  margin: auto;  
  margin-top: 20px;

  img {
    display: flex;
    margin: auto;
    padding: 5px;
    width: 100px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 700px) {
    max-width: 100%;
    max-height: 100%;
  }
`;
