import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Tratamento() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Tratamento de efluentes sanitários e Industriais</span>
            </h1>
            <p>
              Possuímos Estação de Tratamento de Efluentes (ETE), que por meio
              de método físico-químico e biológico consegue tratar efluentes
              sanitários, Industriais e oleosos. Os efluentes oleosos tratados
              em nosso sistema, são processados e obtém-se um Combustível
              reciclado, que pode ser utilizado em Cerâmicas e Usinas de
              Asfalto.
            </p>
          </Grid2>
          <ContainerImg>
            <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
