import React from "react";

import "./not-found.jsx";
import { Container } from "./not-found.jsx";

export const NotFound = () => (
  <>
    <Container>
      <h2>Página não encontrada.</h2>
    </Container>
  </>
);
