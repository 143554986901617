import React from "react";
import { ByGoodApp, Colum, Container, Grid1, Grid2, Grid3, Grid4 } from "./style";
import logoImg from "../../assets/Logo01.svg";
import instagram from "../../assets/instagram.svg";
import whats from "../../assets/whatsapp.svg";
export function Footer() {
  return (
    <>
     
      <Container>
        <Colum>
          <Grid1>
            <img src={logoImg} alt="Logo" />
            
          </Grid1>
          <Grid2>
            <>
              <ul>
                <li>
                  <a href="home">Home</a>
                </li>
                <li>
                  <a href="sobre">Sobre</a>
                </li>
                <li>
                  <a href="servicos">Serviços</a>
                </li>
                <li>
                  <a href="contato">Contato</a>
                </li>
                <li>
                  <a href="https://h58.servidorhh.com/roundcube/" target={"_blank"} rel="noreferrer">Webmail</a>
                </li>
              </ul>
            </>
          </Grid2>
          <Grid3>
            
          </Grid3>
          <Grid4>
            <a
              href="https://www.instagram.com/jcambiental/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>

            <a
              href="https://linktr.ee/jcambiental"
              target="_blank"
              rel="noopener noreferrer"
            >
            <img src={whats} alt="whats" />
              
            </a>
          </Grid4>
        </Colum>
        <ByGoodApp> 
          <p>
          @jcambiental 2022 Todos os direitos reservados - <a
              href="https://www.goodapp.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>By Good App</strong>
            </a>
          </p>
        </ByGoodApp>
      </Container>
    </>
  );
}
