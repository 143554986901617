const data = [
  {


    id: 2,
    category: "Sobre",
    image: '../images/responsabilidade/img2.png',    
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/responsabilidade/img3.png',    
    image_alt: "Sobre"
  },
  {
    id: 4,
    category: "Sobre",
    image: '../images/responsabilidade/img4.png',    
    image_alt: "Sobre"
  },
  {
    id: 5,
    category: "Sobre",
    image: '../images/responsabilidade/img5.png',    
    image_alt: "Sobre"
  },
  
];

export default data;
