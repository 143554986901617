/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container } from "./styles";
import { IoClose } from "react-icons/io5";
import { useEffect } from "react";

export function MenuMobile({ menuIsVisible, setMenuIsVisible }) {
  useEffect(() => {
    document.body.style.overflowY = menuIsVisible ? "hidden" : "auto";
  }, [menuIsVisible]);

  return (
    <Container isVisible={menuIsVisible}>
      <IoClose size={45} onClick={() => setMenuIsVisible(false)} />
      <nav>
        <a href="home">Home</a>
        <a href="sobre">Sobre</a>
        <a href="responsabilidade">Serviços</a>
        <a href="contato">Contato</a>
        <a href="http://jcambiental.uplog.eco.br/wp-login.php?redirect_to=http%3A%2F%2Fjcambiental.uplog.eco.br%2Fwp-admin%2F&reauth=1"
          target="_blank"
          rel="noopener noreferrer"
          >
          Área do Cliente
        </a>
      </nav>
    </Container>
  );
}
