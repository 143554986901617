import React from "react";
import { Container, Grid1, Grid2 } from "./style";

export function ServiceTwo() {
  return (
    <>
      <Container>
        <Grid2>
          <img src="../../../images/responsivo/respnsive.gif" alt="seo" />
        </Grid2>
        <Grid1>
          <h2>
           Sevice Two
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur deleniti earum a fuga.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti temporibus cupiditate numquam reiciendis iure unde praesentium omnis iste, amet aperiam quaerat, exercitationem facilis facere consequuntur dolor repudiandae repellendus, eligendi animi.
          </p>
        </Grid1>
      </Container>
    </>
  );
}
