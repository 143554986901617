import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Locacao() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Locação de equipamentos.</span>
            </h1>

            <p>
            Por possuímos uma frota variada com diversos tipos de equipamentos, disponibilizamos para locação diária e mensal caminhões vácuo, carretas, bitrens, poliguindastes e Rollon Roloff com as suas respectivas caçambas.
            </p>
          </Grid2>
          <ContainerImg>
            <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
