import React from "react";
import { Galery } from "./data/galery";
import { Container, Grid0, Grid1, Grid2 } from "./style";

export function Sobre() {
  return (
    <>
      <Container>
        <Grid0>
        <Grid1>
          <h2>Quem somos</h2>
          <p>
            A <strong>JC Ambiental</strong>, empresa fundada em 2008, especializada em serviços
            de coleta, transporte, tratamento e destinação final de resíduos
            líquidos, sólidos, perigosos, não perigosos e limpezas industriais.
            Estamos localizados estrategicamente no Distrito industrial de São
            Luís – MA, com uma estrutura de galpões, tanques para armazenamento,
            Estação de Tratamento de Efluentes Industriais e Sanitários. Através
            de equipamentos e frota própria possuímos atuação em todos os
            Estados do Norte e Nordeste. Dispomos de equipe qualificada, nossos
            profissionais passam por constantes treinamentos e capacitações.
          </p>
        </Grid1>
        <Grid2>
          <Galery />
        </Grid2>
        </Grid0>
      </Container>
    </>
  );
}
