import React from "react";
import { ServiceCard } from "../Home/Servicos/ServiceCard";
import { Galery } from "./data/galery";

import { Container, ContainerImg, Grid1, Grid2, Grid3 } from "./style";

export function Coleta() {
  return (
    <>
      <Container>
        <Grid1>
          <Grid2>
            <h1>
              <span>Coleta de resíduos de embarcações.</span>
            </h1>

            <p>
              Única empresa habilitada a operar em todos terminais portuários de
              São Luís, por meio de equipamentos especializados e equipe
              qualificada com atendimento 24 horas por dia, 7 dias por semana.
            </p>
          </Grid2>
          <ContainerImg>

          <Galery />
          </ContainerImg>
        </Grid1>

        <Grid3>
          <ServiceCard />
        </Grid3>
      </Container>
    </>
  );
}
