const data = [
  {
    id: 1,
    category: "Sobre",
    image: '../images/coleta_efluentes/img1.png',    
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/coleta_efluentes/img2.png',
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/coleta_efluentes/img3.png',
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/coleta_efluentes/img4.png',
    image_alt: "Sobre"
  },
  
];

export default data;
