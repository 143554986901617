const data = [
  {
    id: 1,
    category: "Sobre",
    image: '../images/limpeza_industrial/img1.png',    
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/limpeza_industrial/img2.png',
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/limpeza_industrial/img3.png',
    image_alt: "Sobre"
  },
  {
    id: 4,
    category: "Sobre",
    image: '../images/limpeza_industrial/img4.png',
    image_alt: "Sobre"
  },
  {
    id: 5,
    category: "Sobre",
    image: '../images/limpeza_industrial/img5.png',
    image_alt: "Sobre"
  },
  
  
];

export default data;
