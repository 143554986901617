import styled from "styled-components";

export const Container = styled.div`
  background-color: #6b6b6b;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 810px;
  margin: auto;

  a {
    align-items: center;
    text-align: center;
    text-decoration: none;
    h5{
      align-items: center;
      color: aquamarine;
    }
  }
`;


export const Bottom = styled.div`
 

  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  border: 1px solid #f3f3f3;
  border-radius: 6px;

  margin: auto;

  

  a {
    align-items: center;
    text-align: center;
    text-decoration: none;
    h5{
      align-items: center;
      color: aquamarine;
    }
  }

  &:hover {
    background-color: #28e496;
    color: #00880b;
    border-color: #ffffff;
    font-weight: bold;

    h5{
      align-items: center;
      color: #1a362d;
    }
  }
`;
