const data = [
  {
    id: 1,
    category: "Sobre",
    image: '../images/coleta_navio/img1.jpeg',    
    image_alt: "Sobre"
  },
  {
    id: 2,
    category: "Sobre",
    image: '../images/coleta_navio/img2.jpeg',
    image_alt: "Sobre"
  },
  {
    id: 3,
    category: "Sobre",
    image: '../images/coleta_navio/img3.jpeg',
    image_alt: "Sobre"
  },
  
];

export default data;
