import React from "react";
import { Description } from "./Description";
import { ServiceCard } from "./ServiceCard";
import { BoxCard, Container, Grid, Grid2 } from "./styles";

export function Services() {
  return (
    <>
      <Container>
        <Grid>
          <BoxCard>
            <ServiceCard />
          </BoxCard>
          <Grid2>
            <Description />
          </Grid2>
        </Grid>
      </Container>
    </>
  );
}
