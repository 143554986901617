import React from "react";
import { Container, Bottom } from "./style";

export function PageEmail() {
  return (
    <>
      <h2>Web Mail</h2>
      <Container>
        <a
          href="https://h55.servidorhh.com/webmail/"
          target={"_blank"}
          rel="noreferrer"
        ><Bottom>

          <h5>Acessar conta de E-mail</h5>
        </Bottom>
        </a>
      </Container>
    </>
  );
}
