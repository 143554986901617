const data = [
  {
    id: 1,
    category: "Sobre",
    image: "../../../../images/politica/politica.png",
    image_alt: "Sobre"
  },
  

  
];

export default data;
