import styled from "styled-components";

export const Container = styled.div`
display: flex;
margin: auto;
width: 100%;
height: 600px;

h2{
  display: flex;
  margin: auto;


}

 
`;