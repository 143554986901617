const data = [
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img1.png',    
    image_alt: "Sobre"
  },
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img2.png',    
    image_alt: "Sobre"
  },
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img3.png',    
    image_alt: "Sobre"
  },
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img4.png',    
    image_alt: "Sobre"
  },
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img5.png',    
    image_alt: "Sobre"
  },
  {
    id: 1,
    category: "Sobre",
    image: '../images/locacao/img6.png',    
    image_alt: "Sobre"
  },
  
  
];

export default data;
