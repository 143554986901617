import React from "react";
import { Galery } from "./data/galery";
import { Container, Grid1, Grid2 } from "./style";

export function Valores() {
  return (
    <>
      <Container>
        <Grid2>
          <Galery />
        </Grid2>
        <Grid1>
          <h2>Política de Gestão</h2>
          <p>
            A JC Ambiental, atuando no segmento de prestação de serviços de
            coleta, transporte, tratamento, destinação final de efluentes,
            resíduos industriais, e transporte de cargas perigosas e não
            perigosas, tem como diretrizes essenciais:
            <br/>
            <br/>
            <strong>Clientes: </strong>
             comprometimento pela satisfação e respeito a clientes internos e
            externos.
            <br/>
            <strong>Colaboradores: </strong> promover continuamente a conscientização
            socioambiental dos colaboradores. 
            <br/>
            <strong>Sociedade: </strong> apoiar programas de
            educação socioambiental.
            <br/>
            <strong>Prevenção: </strong> prevenir a poluição e mitigar
            impactos ambientais.
            <br/>
            <strong>Aspectos Ambientais: </strong> gerenciar os aspectos
            ambientais, minimizando desperdícios.
            <br/>
            <strong>Melhoria Contínua: </strong>
            implementar ações de melhoria contínua dos processos,
            desenvolvimento pessoal, de produtos e serviços.
            <br/>
            <strong>Legislação: </strong>
            cumprir à legislação aplicável.
          </p>
        </Grid1>
      </Container>
    </>
  );
}
