import React from "react";

import { Navigate, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Contato } from "./pages/Contato/Contato";
import { Servicos } from "./pages/Servicos/Servicos";
import { NotFound } from "./pages/NotFound/NotFound";
import { PageSobre } from "./pages/Sobre/PageSobre";
import { Coleta } from "./pages/Coleta/Coleta";
import { ColetaTransporte } from "./pages/ColetaTransporte/ColetaTransporte";
import { Tratamento } from "./pages/Tratamento/Tratamento";
import { Destinacao } from "./pages/Destinacao/Destinacao";
import { Limpeza } from "./pages/Limpeza/Limpeza";
import { Locacao } from "./pages/Locacao/Locacao";
import { Gerenciamento } from "./pages/Gerenciamento/Gerenciamento";
import { Responsabilidade } from "./pages/Responsabilidade/Responsabilidade";
import { PageEmail } from "./pages/emails/PageEmail";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/ma" element={<Home />} />
      <Route path="/sobre" element={<PageSobre />} />
      <Route path="/servicos" element={<Servicos />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/coleta" element={<Coleta />} />
      <Route path="/transporte" element={<ColetaTransporte />} />
      <Route path="/tratamento" element={<Tratamento />} />
      <Route path="/destinacao" element={<Destinacao />} />
      <Route path="/limpeza" element={<Limpeza />} />
      <Route path="/locacao" element={<Locacao />} />
      <Route path="/gerenciamento" element={<Gerenciamento />} />
      <Route path="/webmail" element={<PageEmail />} />
      <Route path="/responsabilidade" element={<Responsabilidade />} />

{/*  Rotas para resolver problema de links do google */}

      <Route path="/ma/home" element={<Home />} />
      <Route path="/ma/sobre" element={<PageSobre />} />
      <Route path="/ma/servicos" element={<Servicos />} />
      <Route path="/ma/contato" element={<Contato />} />
      <Route path="/ma/coleta" element={<Coleta />} />
      <Route path="/ma/transporte" element={<ColetaTransporte />} />
      <Route path="/ma/tratamento" element={<Tratamento />} />
      <Route path="/ma/destinacao" element={<Destinacao />} />
      <Route path="/ma/limpeza" element={<Limpeza />} />
      <Route path="/ma/locacao" element={<Locacao />} />
      <Route path="/ma/gerenciamento" element={<Gerenciamento />} />
      <Route path="/ma/webmail" element={<PageEmail />} />
      <Route path="/ma/responsabilidade" element={<Responsabilidade />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
