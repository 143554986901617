import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: #faf6f1;
  max-width: 100%;
  @media (max-width: 700px) {
    height: 100%;
  }
`;

export const Grid0 = styled.div`
  max-width: 1555px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex: wrap;
`;

export const Grid1 = styled.div`

flex-direction: column;
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (max-width: 700px) {
    width: 100%;
    order: 1;
  }

  p {
    padding: 30px;
  }
`;

export const Grid2 = styled.div`

  display: flex;
  width: 50%;

  justify-content: center;
  align-items: center;
  margin: auto;
  flex: 2 1 400px;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: row;
    order: 2;
  }
`;

export const Card = styled.div`

  display: flex;
  flex-wrap: wrap;

  margin: 8px;

  > img {
    width: 100%;
    max-height: 300px;
    border-radius: 4px;
    border-radius: 6px;
  }
`;
